<template>

    <!--  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░-->
    <!--  ░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░-->
    <!--  ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░-->
    <!--  ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░-->
    <!--  ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░-->
    <!--  ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░-->
    <!--  ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░-->
    <!--  ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░-->
    <!--  ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░-->
    <!--  ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░-->
    <!--  ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░-->
    <!--  ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░-->
    <!--  ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░-->
    <!--  ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░-->

    <div class="nyan" v-if="nyanVisibility" :style="{position: 'absolute', bottom:nyanX + 'px', left: nyanY + 'px' }">
        <div class="ascii-art-text" v-if="transitionNumber === 1" >
            <p>
                ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
                ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
                ░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░
                ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
                ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
                ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
                ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
                ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
                ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
                ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
                ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
                ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
                ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
                ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░</p>

        </div>
        <p class="ascii-art-text" v-if="transitionNumber === 2">

            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░
            ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 3">

            ░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░
            ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 4">

            ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            <!--      ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░This is m-->
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 5">


            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 6">



            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            <!--      ░░░░░░░░░░░░░░░░░░░░░░░░░░This is my Ny-->
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 7">


            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            <!--      ░░░░░░░░░░░░░░░░░░░░░░░░░░░This is Nyan-->
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 8">
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            <!--      ░░░░░░░░░░░░░░░░░This is my Nyanimation-->
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 9">
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 10">
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 11">
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 12">
            ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 13">
            ░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░
            ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 14">
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░
            ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
        </p>
        <p class="ascii-art-text" v-else-if="transitionNumber === 15">
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
            ░░░░░░░░░░▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄░░░░░░░░░
            ░░░░░░░░▄▀░░░░░░░░░░░░▄░░░░░░░▀▄░░░░░░░
            ░░░░░░░░█░░▄░░░░▄░░░░░░░░░░░░░░█░░░░░░░
            ░░░░░░░░█░░░░░░░░░░░░▄█▄▄░░▄░░░█░▄▄▄░░░
            ░▄▄▄▄▄░░█░░░░░░▀░░░░▀█░░▀▄░░░░░█▀▀░██░░
            ░██▄▀██▄█░░░▄░░░░░░░██░░░░▀▀▀▀▀░░░░██░░
            ░░▀██▄▀██░░░░░░░░▀░██▀░░░░░░░░░░░░░▀██░
            ░░░░▀████░▀░░░░▄░░░██░░░▄█░░░░▄░▄█░░██░
            ░░░░░░░▀█░░░░▄░░░░░██░░░░▄░░░▄░░▄░░░██░
            ░░░░░░░▄█▄░░░░░░░░░░░▀▄░░▀▀▀▀▀▀▀▀░░▄▀░░
            ░░░░░░█▀▀█████████▀▀▀▀████████████▀░░░░
            ░░░░░░████▀░░███▀░░░░░░▀███░░▀██▀░░░░░░
        </p>
    </div>

</template>

<script>
import '../../styles/global.css'
import {onMounted, ref} from "vue";
export default {
    name: 'Nyanimation',
    setup() {
        const maxNumberOfSlides = 15;
        let transitionNumber = ref(1);
        let nyanY = ref(0);
        let nyanX = ref(generateNyanStart);
        let nyanVisibility = ref(false);
        let runCount = 0;
        const maxRunCount = 3;

        const generateNyanStart = () => {
            runCount++;
            // generates a random coordinate along the x axis for the Nyan to start at
            return Math.floor(Math.random() * (window.innerHeight - 0 + 1)) + 0;
        }
        const toggleNyan = () => {
            nyanVisibility.value = !nyanVisibility.value;
        }
        const moveAnimation = () => {
            if(nyanY.value + 350 !== window.innerWidth)
            {
                nyanY.value++
                setTimeout(moveAnimation, 1)
            }
            else
            {
                if(runCount !== maxRunCount)
                {
                    toggleNyan()
                    nyanX.value = generateNyanStart();
                    nyanY.value = 0;
                    const sleepTime = Math.floor(Math.random() * (30000 - 0 + 1)) + 0
                    setTimeout(toggleNyan, sleepTime)
                    setTimeout(moveAnimation, sleepTime + 250)
                }
                else {
                    setTimeout(toggleNyan, 10000)
                    return
                }
            }
        }
        const runAnimation = () => {
            if(transitionNumber.value !== maxNumberOfSlides)
            {
                transitionNumber.value++
            }
            else
            {
                transitionNumber.value = 1;
            }
            setTimeout(runAnimation, 500)
        }
        onMounted(() => {
            setTimeout(runAnimation, 500)
            setTimeout(toggleNyan, 500)
            setTimeout(moveAnimation, 500)
        })
        return {
            transitionNumber,
            nyanY,
            nyanX,
            nyanVisibility,
        }
    },
}
</script>