<template>
    <RouterView />
</template>

<script>
import './styles/global.css'

export default {
    name: 'App',
    components: {},
}
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;

    margin-top: 60px;
}
</style>
